import * as Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import { Subject } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  constructor(private injector: Injector) {}

  authService = this.injector.get(AuthService);
  webSocketEndPoint: string = `${environment.serverUrl}` + 'rest/auth/ws';
  topic: string = '/user/queue/messages';
  pollTopic: string = '/topic/review';
  stompClient: any;
  profileId: any;
  headers = {
    'X-Frame-Options': 'deny',
    Authorization: `Bearer ${this.authService.getToken()}`,
  };
  private updateNotificationCountViaWebSocket: Subject<any[]> =
    new Subject<any>();
  updateNotificationCountViaWebSocket$ =
    this.updateNotificationCountViaWebSocket.asObservable();
  private connected: boolean = false;

  _connect(profileId: any) {
    //let socket = new WebSocket("ws://localhost:8111/rest/auth/ws"); // prod
    let ws = new SockJS(this.webSocketEndPoint);

    this.profileId = profileId;
    console.log('Initialize WebSocket Connection');
    this.stompClient = Stomp.over(ws);
    const _this = this;
    _this.stompClient.connect(
      this.headers,
      function (fram: any) {
        _this._send(profileId);
        _this.stompClient.subscribe(_this.topic, function (sdkEvent: any) {
          _this.onMessageReceived(sdkEvent);
        });

        //_this.stompClient.reconnect_delay = 2000;
      },
      this.errorCallBack
    );
  }

  _disconnect() {
    if (this.stompClient !== null) {
      this.stompClient.disconnect();
    }
    console.log('Disconnected');
  }

  // on error, schedule a reconnection attempt
  errorCallBack(error: any) {
    console.log('errorCallBack -> ' + error);
    setTimeout(() => {
      this._connect(this.profileId);
    }, 5000);
  }

  /**
   * Send message to sever via web socket
   * @param {*} message
   */
  _send(message: any) {
    console.log('sending profileId via web socket');
    this.stompClient.send('/app/ws', this.headers, JSON.stringify(message));
  }

  onMessageReceived(message: any) {
    console.log('Message Recieved from Server :: ' + message);
    //message = JSON.stringify(message.body);
    this.updateNotificationCountViaWebSocket.next(message.body);
  }

  connect(topic: string, callback: (message: any) => void) {
    if (!this.connected) {
      const socket = new SockJS(this.webSocketEndPoint);
      this.stompClient = Stomp.over(socket);

      this.stompClient.connect({}, (frame: string) => {
        console.log('Connected: ' + frame);
        this.connected = true;

        // Automatically subscribe to the topic upon connection
        this.subscribe(topic, callback);
      });
    }
  }

  disconnect() {
    if (this.connected && this.stompClient) {
      this.stompClient.disconnect(() => {
        console.log('Disconnected');
        this.connected = false;
      });
    }
  }

  subscribe(topic: string, callback: (message: any) => void) {
    if (this.connected && this.stompClient) {
      this.stompClient.subscribe(topic, (message: any) => {
        callback(JSON.parse(message.body));
      });
    } else {
      console.warn('WebSocket is not connected. Cannot subscribe to the topic.');
    }
  }

}
