import { PollOptionsDTO } from "./pollOptions.modal";


export class PollDTO {
    question: string;
    options: Array<PollOptionsDTO>;
    pollDurationInDays: number;

    constructor() {
        this.question = "";
        this.options = [
            { optionId: 0, optionText: '', votes: 0, isVoted: false },
            { optionId: 0, optionText: '', votes: 0, isVoted: false },
        ];
        this.pollDurationInDays = 3;
    }
}