import { Pipe, PipeTransform } from '@angular/core';
import { NgxLinkifyjsService } from 'ngx-linkifyjs-v2';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'applinkifyHtml',
})
export class NgxLinkifyjsHtmlPipe implements PipeTransform {
  constructor(
    public linkifyService: NgxLinkifyjsService,
    private sanitizer: DomSanitizer
  ) {}

  transform(value: string, options?: { limit?: boolean, limitChars?: number }): SafeHtml {
    if (!value) return value;

    // Step 1: Replace mentions first
    let transformedHtml = this.replaceMentions(value);

    // Step 2: Find links in the transformedHtml and replace them
    const links = this.linkifyService.find(transformedHtml);
    links.forEach((link) => {
      const escapedLinkValue = this.escapeSpecialCharacters(link.value);
      transformedHtml = transformedHtml.replace(
        new RegExp(`\\b${escapedLinkValue}\\b`, 'g'),
        `<a href="${link.href}" target="_blank" rel="noopener noreferrer">${link.value}</a>`
      );
    });

    // Step 3: Apply the character limit if enabled
    if (options?.limit) {
      const limitChars = options?.limitChars || 40;
      const truncatedHtml = this.truncateHtml(transformedHtml, limitChars);

      // Add ellipsis only if truncation occurs
      return this.sanitizer.bypassSecurityTrustHtml(truncatedHtml.truncated ? truncatedHtml.html + '...' : truncatedHtml.html);
    }

    // Step 4: Return sanitized HTML if no truncation
    return this.sanitizer.bypassSecurityTrustHtml(transformedHtml);
  }

  private replaceMentions(html: string): string {
    // Update the regular expression to include dots (.) and underscores (_)
    return html.replace(
      /<span class="mention" data-mention="(@[\w._]+)">(.+?)<\/span>/g, 
      (match, mention) => {
        const username = mention.substring(1); // Remove the '@' symbol
        return `<span class="mention"><a href="/profile/view-profile/${username}" class="linkify linkify-mention">${mention}</a></span>`;
      }
    );
  }

  // Helper function to escape special characters in the link value
  private escapeSpecialCharacters(value: string): string {
    return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  // Function to safely truncate HTML at the last full word or tag
  private truncateHtml(html: string, maxLength: number): { html: string, truncated: boolean } {
    // Decode HTML entities to ensure characters like `&nbsp;` are handled correctly
    const decodedHtml = this.decodeHtmlEntities(html);

    // Strip HTML tags to calculate the visible length
    const strippedHtml = decodedHtml.replace(/<[^>]*>/g, '');

    // If the content is shorter than the max length, return the original HTML
    if (strippedHtml.length <= maxLength) {
      return { html, truncated: false };
    }

    // Truncate the visible text to the limit
    let truncatedText = strippedHtml.substring(0, maxLength);

    // Step 1: Rebuild the HTML structure with truncated visible text
    let truncatedHtml = this.buildTruncatedHtml(html, truncatedText);

    // Step 2: Return truncated HTML with ellipsis if necessary
    return { html: truncatedHtml, truncated: true };
  }

  // Helper function to decode HTML entities (e.g., &nbsp; => " ")
  private decodeHtmlEntities(text: string): string {
    const doc = new DOMParser().parseFromString(text, 'text/html');
    return doc.documentElement.textContent || '';
  }

  // Helper function to rebuild the HTML after truncation
  private buildTruncatedHtml(originalHtml: string, truncatedText: string): string {
    let resultHtml = '';
    let remainingText = truncatedText;
    let lastIndex = 0;

    // Iterate through the original HTML to rebuild it
    let match;
    const regex = /(<[^>]+>)/g; // This regex matches any HTML tag

    // Iterate through the string and keep track of where we are in the original HTML
    while ((match = regex.exec(originalHtml)) !== null) {
      // Get the tag before the next match
      const tag = match[0];

      // Find the position where the tag starts in the original HTML
      const tagStartIndex = match.index;

      // Add content before the tag (the text part)
      const textContent = originalHtml.slice(lastIndex, tagStartIndex);
      lastIndex = tagStartIndex + tag.length;

      if (remainingText.length > textContent.length) {
        resultHtml += textContent + tag;
        remainingText = remainingText.slice(textContent.length);
      } else {
        resultHtml += remainingText;
        return resultHtml;
      }
    }

    // Add the final part of the text if there's anything left
    if (remainingText.length > 0) {
      resultHtml += remainingText;
    }

    return resultHtml;
  }
}
