<!-- signup -->
<div class="content pt-md-5 m-0 signin" [ngClass]="{ 'h-100': logflag }">
  <div class="row" [ngClass]="{'mt-0' : step == 3,
  'mt-sm-0': step === 1 || step === 2 || step === 4,
  'signin-row':step === 1 || step === 2 || step === 4}">
    <div class="col-sm-8 col-12 offset-sm-2 offset-md-1" *ngIf="step == 3  && this.user.defaultProfileId == null">
      <h3 class="text-center text-md-left mt-sm-0 mt-3 mb-3 fw-700">Just a few steps left and then you'll be all set to
        use CitizeX!</h3>
    </div>
    <div class="col-12 col-sm-8 col-md-9 col-lg-7 offset-sm-2 offset-md-1">
      <h1 class="text-center text-md-left title mt-sm-0 mt-3">
        <span *ngIf="step == 1 || step == 2 || step == 4">Account Creation</span>
        <span *ngIf="step == 3">Verify your Identity</span>
      </h1>
      <div class="progress signup-progress-bar mt-3" *ngIf="step == 1 || step == 2 || step == 4">
        <div  class="progress-bar" role="progressbar"
          [style.width.%]="accountSetUp" [attr.aria-valuenow]="accountSetUp" aria-valuemin="0" aria-valuemax="100">
        </div>
      </div>

      <div class="progress signup-progress-bar mt-3" *ngIf="step == 3 && this.user.defaultProfileId == null">
        <div class="progress-bar" role="progressbar" [style.width.%]="verifyIdentity"
          [attr.aria-valuenow]="verifyIdentity" aria-valuemin="0" aria-valuemax="100"></div>
      </div>

      <p class="font-14 fw-500 mb-3 mt-2" *ngIf="step == 1 || step == 2 || step == 4">{{accountSetUp}}%</p>
      <p class="font-14 fw-500 mb-3 mt-2" *ngIf="step == 3  && this.user.defaultProfileId == null">{{verifyIdentity}}%</p>
    </div>
  </div>
  <!-- Signup step == 1 -->
  <div class="row mt-sm-0 mt-2 m-0 pb-2 center" *ngIf="step == 1">
    <div class="col-12 col-sm-8 col-md-9 col-lg-7 offset-sm-2 offset-md-1 px-sm-0">
      <h1 class="text-center text-md-left title font-1">Sign Up</h1>
      <div class="col-md-12 pl-0 d-flex">
        <div class="col-8 px-0">
          <p class="py-1 sub-title fw-600 font-14">* Please use your <span class="sub-title fw-900 font-14 text-danger">Full Legal Name</span> as shown on your government issued ID when creating your CitizeX account</p>
        </div>
        <div class="col pr-md-3 pr-0">
          <img src="../../../assets/images/Id_img.png" class="id-img py-1">
        </div>
      </div>
      <form class="row m-0 square-form needs-validation" action="activation.html" (ngSubmit)="(f.form.valid)"
        #f="ngForm" novalidate>
        <div class="col-md-12 my-2 alert alert-danger" *ngIf="f.submitted && (f.touched && f.invalid)">
          Please fill up all the details correctly.
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <!-- .alert-dangers -->

        <div class="col-md-12 my-2 alert alert-danger" *ngIf="invalidSignUp == true">
          {{ errMsg }}
          <button type="button" (click)="invalidSignUp = false" class="close" data-dismiss="alert" aria-label="Close">
            <span>&times;</span>
          </button>
        </div>

        <div class="col-md-12 my-2 alert alert-danger" *ngIf="this.phoneExist == true">
          This phone number is already verified. Please use different number.
          <button type="button" (click)="this.phoneExist = false" class="close" data-dismiss="alert" aria-label="Close">
            <span>&times;</span>
          </button>
        </div>

        <!-- .alert-dangers -->
        <div class="col-md-6 p-0 pr-md-3">
          <div class="form-group">
            <input type="text" [ngClass]="{ 'form-control': true, 'has-value': !!fname.value }" id="firstName"
              name="firstname" #fname="ngModel" [(ngModel)]="user.firstName" tabindex="1" required  NoLeadingWhitespace/>
            <label for="firstName">First & Middle Name*</label>
            <div class="invalid-feedback" *ngIf="f.submitted && fname.invalid">
              <span *ngIf="fname.errors?.['required']">
                Please enter First & Middle Name.</span>
              <span *ngIf="fname.errors?.['pattern']">
                Please enter Valid First & Middle Name.</span>
            </div>
          </div>
        </div>

        <div class="col-md-6 p-0 pr-md-3">
          <div class="form-group">
            <input type="text" [ngClass]="{ 'form-control': true, 'has-value': !!lname.value }" id="lastname"
              name="lastname" #lname="ngModel" [(ngModel)]="user.lastName" tabindex="2" required  NoLeadingWhitespace/>
            <label for="lastname">Last Name*</label>
            <div class="invalid-feedback" *ngIf="f.submitted && lname.invalid">
              <span *ngIf="lname.errors?.['required']">
                Please enter Last Name.</span>
              <span *ngIf="lname.errors?.['pattern']">
                Please enter Valid Last Name.</span>
            </div>
          </div>
        </div>

        <!-- <div class="col-md-12 p-0 pr-md-3 align-self-start">
          <div class="form-group">
            <div class="right-icon"></div>
            <ejs-datepicker
              #DOB
              id="DOB"
              name="DOB"
              #dob="ngModel"
              placeholder="MM-DD-YYYY"
              [(ngModel)]="user.dateOfBirth"
              [max]="maxDate"
              format="MM-dd-yyyy"
              start="Decade"
              depth="Month"
              strictMode="true"
              aria-placeholder="YYYY-MM-DD"
              [htmlAttributes]="{ inputmode: 'none' }"
              [ngClass]="{ 'form-control': true, 'has-value': !!dob.value }"
              (focus)="$event.model.show()"
              (change)="checkDOB($event)"
              tabindex="3"
              required
            ></ejs-datepicker>
            <label for="DOB">Date of birth*</label>

            <div class="invalid-feedback" *ngIf="f.submitted && dob.invalid">
              <span *ngIf="dob.errors.required"> Please enter Date Of Birth.</span>
            </div>
            <div class="invalid-feedback" *ngIf="this.validAge == false">
              <span *ngIf="this.validAge == false">Your age should be 18+</span>
            </div>
          </div>
        </div> -->
        <!-- .col -->

        <div class="col-md-12 p-0 pr-md-3">
          <div class="form-group">
            <input type="text" [ngClass]="{ 'form-control': true, 'has-value': !!emailId.value }" id="emailID"
              name="emailID" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+([-.][a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$" #emailId="ngModel"
              [(ngModel)]="user.email" tabindex="5" required  NoLeadingWhitespace/>
            <label for="emailID">Email Address* </label>
            <div class="invalid-feedback" *ngIf="f.submitted && emailId.invalid">
              <span *ngIf="emailId.errors?.['required']">
                Please enter Email address.
              </span>
              <span *ngIf="emailId.errors?.['pattern']">
                Please enter a valid Email address.
              </span>
            </div>
          </div>
        </div>
        <!-- .col -->

        <div class="px-0">
          <div class="form-group checkbox-form-group signup-checbox-group">
            <!-- <input type="checkbox" id="terms" name="terms-and-condition" ngModel #terms="ngModel" [checked]="termsAndConditionFlag == true" [(ngModel)]="termsAndConditionFlag" tabindex="6" required />
              <label for="terms-and-condition" class="font-14"> I agree with
                 <a class="btn btn-link btn-link-theme font-14 p-0 ml-1" data-toggle="modal" data-target="#termAndCondition">Terms &amp; Conditions.</a> 
                 and 
                 </label> -->

            <input class="form-check-input col-1 ml-sm-2 ml-0 Signup-check-box" type="checkbox" id="terms-and-condition"
              name="terms-and-condition" ngModel #terms="ngModel" tabindex="6" [(ngModel)]="termsAndConditionFlag"
              required />
            <label for="terms-and-condition " class="form-check-label col-11 ml-sm-4"> I agree with
              <a class=" p-0 " href="/terms-and-conditions" target="_blank">
                <b>Terms &amp; Conditions</b></a> and
              <a class="  p-0 " href="/privacy-and-policy" target="_blank"><b>Privacy Policy.</b> </a>
            </label>

            <!-- <div class="col-12 text-center mt-4 mb-3">
                By signing up, you agree
                <a class="btn-link btn-underline btn-link-font fw-700"  href="/terms-and-conditions" target="_blank">
                  Terms and conditions
                </a>
                and 
                <a href="/privacy-and-policy" target="_blank" class="btn-link btn-underline btn-link-font fw-700">
                  Privacy Policy
                </a>
    
              </div> -->
            <div class="invalid-feedback checkbox-error-msg" *ngIf="f.submitted && terms.invalid">
              <span *ngIf="terms.errors?.['required']">Sorry, you must accept terms and conditions and Privacy
                Policy.</span>
            </div>
          </div>
        </div>
        <!-- .col -->

        <div class="col-md-12 pt-md-3 pt-4 pl-0 text-center">
          <input type="submit" class="btn btn-black w-100" (click)="signUp(f.invalid)" value="Next" tabindex="7" />
          <p class="font-14 font-gray mt-3">
            Already have an account ?
            <a [routerLink]="['/login']" class="btn btn-link btn-link-theme font-14 p-0" tabindex="8">
              &nbsp;Sign In
            </a>
          </p>
        </div>
        <!-- .col -->
      </form>
    </div>
    <!-- .col-md-12 -->
  </div>
  <!-- .row step==1 -->
  <!-- Signup step == 1  Ends-->

  <!-- Signup step == 2 -->
  <div class="row m-0 py-2 center" *ngIf="step == 2">
    <div class="col-12 col-sm-8 col-md-9 col-lg-7 offset-sm-2 offset-md-1 pl-sm-0">
      <h1 class="text-center text-md-left title">Sign Up</h1>
      <p class="text-center text-md-left py-1 sub-title">Activate your account</p>
      <h6 class="text-center text-md-left sub-title pt-4 pb-2">
        We've sent an activation code to your email.<br />
        Please enter that code below.
      </h6>
      <form class="row m-0 square-form needs-validation" (ngSubmit)="(f.form.valid)" #f="ngForm" novalidate
        autocomplete="off">
        <div *ngIf="activationsent == true" class="col-md-12 my-2 alert alert-info">
          Activation Code sent again.
          <button type="button" (click)="activationsent = false" class="close" data-dismiss="alert" aria-label="Close">
            <span>&times;</span>
          </button>
        </div>
        <div class="col-12 activation-grid form-group">
          <div [ngClass]="{
              'is-invalid':
                incorrectActCode == true ||
                (f.submitted && (f.untouched || f.invalid))
            }" *ngFor="
              let item of actCodeArr;
              let index = index;
              trackBy: trackByIndex
            ">
            <div class="input-field my-0">
              <input type="text" tabindex="{{ index + 1 }}" id="activationCode{{ index }}" name="actCodee[index]"
                class="form-control text-center activation-field h-2" maxlength="1" required #act="ngModel"
                (input)="keytab(this.index, $event, 'input')" (keyup)="keytab(this.index, $event, 'keyup')"
                (focus)="onFocus($event)" (paste)="onPestCode($event, 'activationCode', 6)"
                [(ngModel)]="actCodeArr[index]" inputmode="tel" />
            </div>
          </div>
          <div class="text-center invalid-feedback">
            <span *ngIf="f.submitted && (f.untouched || f.invalid)">
              Please Enter Activation Code.
            </span>
            <span *ngIf="incorrectActCode == true">
              Invalid Activation Code.
            </span>
          </div>
        </div>
        <!-- .col -->

        <div class="col-md-12 pt-md-3 pt-4 text-center">
          <div class="row">
            <div class="col-6 pr-1 text-left">
              <input type="button" (click)="previous()" class="btn btn-underline mt-2 px-0" value="Back" tabindex="7" />
            </div>
            <div class="col-6 pr-1">
              <input type="submit" (click)="activationCodeVerify(f.invalid)" class="btn btn-black mt-2 w-100"
                value="Next" tabindex="7" />
            </div>
          </div>
          <p class="font-14 font-gray mt-3">
            Didn't get activation code ?
            <a (click)="resendActivationCode(f)" class="btn btn-link btn-link-theme font-14 p-0" tabindex="8">
              &nbsp;Resend now
            </a>
          </p>
          <p class="font-14 font-gray mt-2">
            Already have an account ?
            <a [routerLink]="['/login']" class="btn btn-link btn-link-theme font-14 p-0" tabindex="9">
              &nbsp;Sign In
            </a>
          </p>
        </div>
        <!-- .col -->
      </form>
    </div>
    <!-- .col-md-12 -->
  </div>
  <!-- .row step==2 -->
  <!-- Signup step==2 Ends-->

  <!-- Signup step == 3 -->
  <div class="row m-0 py-2 center" *ngIf="step == 3">
    <div class="col-md-9 col-sm-9 offset-md-1 offset-sm-2 pl-sm-0">
      <!-- <h1 class="text-md-left text-center">Verify your Identity</h1> -->
      <!-- <div class="mt-3 p-3 bg-very-light-warning" *ngIf="[7, 15, 30].includes(diffDate)"> -->
      <div class="mt-3 p-3 bg-very-light-warning" *ngIf="this.user.verified == false">
        CitizeX believes the best way to get honest feedback and information is by it having the person posting it
        identify themselves. Therefore, not only does CitizeX restrict anonymous users to post, but it links it every
        feedback and comment to the user making it. Therefore, you must have your identity verified using the following
        steps below in order to be eligible to contribute to CitizeX.
      </div>
      <h6 *ngIf="this.user.verified == false" class="py-2 text-md-left text-center">Please complete the following steps
        to get verified on CitizeX.</h6>
      <h6 *ngIf="this.user.verified == true" class="py-2 text-md-left text-center font-success">Congratulations, You
        have already completed 2 out of 2 verification processes.</h6>
      <div class="col-md-12 my-2 alert alert-danger" *ngIf="fullyVerified == true">
        You are still not verified by verification platforms
        <button (click)="fullyVerified = false" type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span>&times;</span>
        </button>
      </div>
      <form class="row m-0" (ngSubmit)="(f.form.valid)" #f="ngForm" novalidate>
        <div class="col-12 p-0">
          <button class="verification-option row" (click)="openTextVerificationModal()">
            <i class="col-auto fi flaticon-circle"></i>
            <i class="col-auto fi flaticon-text-message-1"></i>
            <p class="col-auto">Text Verification</p>
            <span *ngIf="textVerified == true" class="col-auto verified"><i class="fi flaticon-success">
                Verified</i></span>
          </button>
        </div>
        <div class="col-12 p-0">
          <button class="verification-option row" (click)="openVerificationModal()">
            <i class="col-auto fi flaticon-circle"></i>
            <i class="col-auto fi flaticon-id-verification"></i>
            <p class="col-auto">Identity Verification</p>
            <span *ngIf="veriffVerified == true" class="col-auto verified"><i class="fi flaticon-success">
                Verified</i></span>
            <span style="font-size: 13px" class="col-auto verified font-danger" *ngIf="veriffStatus == 'rejection' && veriffVerified == false">
              <!-- Your request has been rejected due to mismatch in ID, if you wish
              to update your user information -->
              Your request has been rejected due to a mismatch in ID. 
              To update your user information, please click here and try again.
              <a style="color: blue" [routerLink]="['/edit-user']"><u>click here</u></a>
              and retry.
            </span>
            <span style="font-size: 13px" *ngIf="veriffStatus == 'declined' && veriffVerified == false"
              class="col-auto verified font-danger">
              Your request has been declined , please retry.</span>
            <span style="font-size: 13px" *ngIf="veriffErrMsg != null && veriffVerified == false"
              class="col-auto verified font-danger">
              {{ veriffErrMsg }}</span>
          </button>
          <div class="mt-2" *ngIf="veriffStatus == 'rejection' && veriffVerified == false">
            <p class="font-16 fw-600">Possible reasons for the rejection include:</p>
            <div class="col mt-1 pl-4 mb-2">
              <ul class="ul-style">
                <li>Missing middle name in the 'first and middle name' field during sign-up.</li>
                <li>The name entered at sign-up does not match the name on your ID.</li>
                <li>An extra space after your last name.</li>
              </ul>
            </div>
          </div>
          <div *ngIf="veriffStatus != 'rejection'">
            * If you have already applied for verification, please wait for a
            few minutes and check back again or
            <a href="/signup"><u>refresh</u></a>.
          </div>
        </div>
        <!-- .col -->

        <div class="col-md-12 pt-md-3 pt-4 justify-content-end px-0 row mx-0 mb-sm-0 mb-4">
          <button type="button" *ngIf="!logflag" class="btn btn-underline mr-auto px-0"
            (click)="previous()">Back</button>
          <button type="button" *ngIf="!(textVerified && !logflag)" class="btn btn-underline mx-2"
            (click)="skip()">Skip</button>
          <input type="submit" class="btn btn-black" (click)="next()" value="Next" />
        </div>
        <!-- .col -->
      </form>
    </div>
    <!-- .col-md-8 -->
  </div>
  <!-- .row step==3 -->
  <!-- Signup step==3 Ends-->

  <!-- Signup step == 4 -->
  <div class="row m-0 py-2 center" *ngIf="step == 4">
    <div class="col-12 col-sm-8 col-md-9 col-lg-7 offset-sm-2 offset-md-1 pl-sm-0">
      <h1 class="text-center text-md-left title">Sign Up</h1>
      <!-- <p class="text-center text-md-left py-1 sub-title">You will need Username and Password</p> -->
      <p class="text-center text-md-left py-1 sub-title">
        All new passwords must contain at least 8 characters. There must be at least one capital and one lower-case
        letter (Aa-Zz), one special symbol (#, &, % etc), and one number (0-9) in your password for the best strength.
      </p>
      <form class="row m-0 square-form needs-validation" (ngSubmit)="(f.form.valid)" #f="ngForm" novalidate>
        <div class="col-md-12 my-2 alert alert-danger" *ngIf="f.submitted && (f.untouched || f.invalid)">
          Please fill up all the details correctly.
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="col-md-12 my-2 alert alert-danger" *ngIf="match == false && !(f.untouched || f.invalid)">
          Password and Confirm Password does not match.
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <!-- .alert-dangers -->

        <!-- <div class="col-md-12 px-0">
          <div class="form-group">
            <input
              type="text"
              [ngClass]="{ 'form-control': true, 'has-value': !!uname.value }"
              id="userName"
              name="username"
              pattern="^[A-Za-z0-9/_/-/.]+$"
              #uname="ngModel"
              [(ngModel)]="user.userName"
              (focusout)="checkExistingUsername()"
              tabindex="1"
              required
            />
            <label for="input" class="control-label">Username</label>
            <div class="invalid-feedback" *ngIf="f.submitted && uname.invalid">
              <span *ngIf="uname.errors.required"> Please Enter Username.</span>
              <span *ngIf="uname.errors.pattern"> Please enter a valid Username. Username should be alphanumeric and it does not contain spaces. </span>
            </div>
            <div class="invalid-feedback" *ngIf="userExists == true">
              <span> Username already exists, please try a different username. </span>
            </div>
          </div>
        </div> -->
        <!-- .col -->

        <div class="col-md-12 p-0">
          <div class="form-group">
            <input type="password" [ngClass]="{ 'form-control': true, 'has-value': !!pwd.value }" id="password"
              name="password" pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$" minlength="8"
              maxlength="40" [(ngModel)]="user.password" #pwd="ngModel" tabindex="2" required autocomplete="off"
              (focus)="this.passwordMeter = true" (focusout)="this.passwordMeter = false" />
            <label for="input">Password</label>
            <button type="button" *ngIf="!!pwd.value" class="right-icon toggle-password" toggle="#password"
              tabindex="3">
              <i class="fi flaticon-eye"></i>
            </button>
            <div class="invalid-feedback" *ngIf="f.submitted && pwd.invalid">
              <span *ngIf="pwd.errors?.['required']">Please Enter the Password.</span>
              <span *ngIf="pwd.errors?.['minlength']">
                Password must be at least 8 characters long.</span>
              <span *ngIf="pwd.errors?.['maxlength']">
                Password is limited upto 40 characters.</span>
              <span *ngIf="pwd.errors?.['pattern']">Please enter valid pasword,it must contain at least one capital
                and one lower-case letter (Aa-Zz), one special symbol (#, &, %
                etc), and one number (0-9) in your password for the best
                strength.</span>
            </div>
          </div>
          <password-strength-meter *ngIf="this.passwordMeter" class="font-16" type="password-strength-meter"
            name="passMeter" id="passMeter" [colors]="['darkred', 'orangered', 'orange', 'yellowgreen']"
            [minPasswordLength]="8" [enableFeedback]="true" [password]="pwd.value"></password-strength-meter>
        </div>
        <!-- .col -->

        <div class="col-md-12 p-0">
          <div class="form-group">
            <input type="password" [ngClass]="{ 'form-control': true, 'has-value': !!pwdconf.value }" id="passwordConf"
              name="passwordConf" [(ngModel)]="user.passwordconf" (focusout)="confirmPassword()" #pwdconf="ngModel"
              tabindex="5" required autocomplete="off" />
            <label for="input" class="control-label">Confirm Password</label>
            <button type="button" *ngIf="!!pwdconf.value" class="right-icon toggle-password" toggle="#passwordConf"
              tabindex="6">
              <i class="fi flaticon-eye"></i>
            </button>
            <div class="invalid-feedback" *ngIf="f.submitted && pwdconf.invalid">
              <span *ngIf="pwdconf.errors?.['required']">Please Re-enter the password.</span>
            </div>
          </div>
        </div>
        <!-- .col -->

        <div class="col-md-12 pt-4 text-center px-0">
          <div class="row">
            <div class="col-6 pr-1 text-left">
              <input type="button" (click)="previous()" class="btn btn-underline px-0" value="Back" tabindex="7" />
            </div>
            <div class="col-6 pl-1">
              <input type="submit" class="btn btn-black w-100" (click)="activateProfile(f.invalid)" value="Sign Up"
                tabindex="8" />
            </div>
          </div>
          <h6 class="mt-3">
            Already have an account ?
            <a [routerLink]="['/login']" class="btn btn-link btn-link-theme font-14 p-0" tabindex="9">&nbsp;Sign In</a>
          </h6>
        </div>
        <!-- .col -->
      </form>
    </div>
    <!-- .col-md-8 -->
  </div>
  <!-- .row step==4 -->
  <!-- Signup step==4 Ends-->
</div>
<!-- .content -->
<!-- signup Ends -->

<!-- <div id="termAndCondition" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="termAndConditionTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-dialog-centered modal-dialog-custom modal-dialog-scrollable modal-theme modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header py-3">
        <h4 class="modal-title" id="termAndConditionTitle">Terms and Conditions</h4>
      </div>
      <div class="modal-body mxh-80vh overflow-auto">
        <br />

        <h6 class="bold text-center"><u>END USER LICENSE AGREEMENT</u></h6>

        <p class="mt-3 ml-3 mr-3 text-justify">
          The following END USER LICENSE AGREEMENT (the “EULA”) governs the use of Jikia (“we,” “our,” or “us”) websites, located at www.citizex.info, or participating in any online features, services and/or programs oﬀered by us
          (collectively, the “Website”). This EULA is in eﬀect for all of our Websites.
        </p>

        <p class="mt-3 bold ml-5 mr-5 text-justify">
          BY ACCESSING OR USING OUR WEBSITES, YOU ARE ENTERING INTO A LEGAL CONTRACT WITH JIKIA REGARDING YOUR USE OF THE WEBSITES AND YOU AGREE TO BE BOUND BY THIS EULA. IF YOU DO NOT AGREE TO ANY PORTION OF THIS EULA, YOU SHOULD NOT
          ACCESS OR OTHERWISE USE THE WEBSITE.
        </p>

        <p class="mt-3 bold ml-5 mr-5 text-justify">
          YOU SHOULD CAREFULLY REVIEW THIS ENTIRE EULA. INCLUDED IN THIS LEGAL CONTRACT ARE (i) REQUIREMENTS THAT YOU PROVIDE TRUTHFUL, ACCURATE, CURRENT AND COMPLETE INFORMATION ABOUT YOUR IDENTITY; (ii) PROHIBITIONS ON ALLOWING ANYONE OR
          ANY ENTITY OTHER THAN YOURSELF USE OF YOUR USER ACCOUNT; AND (iii) TERMS CLEARLY INDICATING YOU ARE RESPONSIBLE FOR ANY LEGAL ISSUES CAUSED BY YOUR CONTENT OR YOUR USE OF THE WEBSITES.
        </p>

        <h6 class="mt-4 text-center mb-4 bold"><u>TABLE OF CONTENTS</u></h6>

        <div class="row mx-0 ml-5 mr-5 mt-2">
          <div class="col-auto pl-0 pr-2">1.</div>
          <div class="col px-0">CONVENIENCE AND INFORMATION ONLY</div>
        </div>
        <div class="row mx-0 ml-5 mr-5 mt-2">
          <div class="col-auto pl-0 pr-2">2.</div>
          <div class="col px-0">USER ACCOUNT</div>
        </div>
        <div class="row mx-0 ml-5 mr-5 mt-2">
          <div class="col-auto pl-0 pr-2">3.</div>
          <div class="col px-0">ACCURATE AND TRUTHFUL INFORMATION</div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2">
          <div class="col-auto pl-0 pr-2">4.</div>
          <div class="col px-0">ACCOUNT DEACTIVATION</div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2">
          <div class="col-auto pl-0 pr-2">5.</div>
          <div class="col px-0">OBJECTIONABLE MATERIAL</div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2">
          <div class="col-auto pl-0 pr-2">6.</div>
          <div class="col px-0">CONTENT</div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2">
          <div class="col-auto pl-0 pr-2">7.</div>
          <div class="col px-0">OUR PARTICIPATION</div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2">
          <div class="col-auto pl-0 pr-2">8.</div>
          <div class="col px-0">NO WARRANTIES, LIMITATIONS OF LIABILITY, YOUR INDEMNIFICATION OF US</div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2">
          <div class="col-auto pl-0 pr-2">9.</div>
          <div class="col px-0">JURISDICTION AND VENUE</div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2">
          <div class="col-auto pl-0 pr-2">10.</div>
          <div class="col px-0">MISCELLANEOUS</div>
        </div>

        <div class="row mx-0 mt-5 ml-3 mr-3 text-justify">
          <div class="col-auto pl-0 pr-2"><b>1.</b></div>
          <div class="col px-0">
            <b>CONVENIENCE AND INFORMATION ONLY.</b> The Websites are provided to you as a convenience and for your informaKon only. By merely providing access to the Website, we do <b><u>NOT</u></b> guarantee, warrant or represent that:
          </div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">a.</div>
          <div class="col px-0">
            Any materials, statements, reviews, ratings, opinions, documents, images, graphics, logos, designs, audio, video, comments and any other information provided from or on the Websites (collectively, the “Content”) is accurate or
            complete;
          </div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">b.</div>
          <div class="col px-0">The Content is up-to-date or current;</div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">c.</div>
          <div class="col px-0">We have any obligaKon to update any Content;</div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">d.</div>
          <div class="col px-0">We will keep your Content confidential or restrict third parties from any use of your Content;</div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">e.</div>
          <div class="col px-0">
            Any use, copy, reproduction, adaptation, publication, performance, or audio recording of the Content is free from allegations, claims, or lawsuits, including those based on allegations of intellectual property infringement,
            libel or slanderous defamatory statements, and/or violations of the right of publicity, including personality rights, the use of one's identity, such as name, image, likeness, or other unequivocal identifiers;
          </div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">f.</div>
          <div class="col px-0">The Content is free from changes caused by a third party;</div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">g.</div>
          <div class="col px-0">g. Your access to the Website will be free from interruptions, errors, viruses or other harmful components;</div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">h.</div>
          <div class="col px-0">No data breach will ever occur; and/or</div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">i.</div>
          <div class="col px-0">Information transmitted through the Internet is secure, or that such transmissions are free from delay, interruption, interception or error.</div>
        </div>

        <div class="row mx-0 mt-3 ml-3 mr-3 text-justify">
          <div class="col-auto pl-0 pr-2"><b>2.</b></div>
          <div class="col px-0">
            <b>USER ACCOUNT.</b> To access the Website, we require you to select a username and password, and create a user account (“User Account”). User Accounts are unique to an individual real person. You are prohibited from creating or
            using a User Account on behalf of an entity, organization, group, non-profit, university or other educational institution, political organization, government or governmental agency or entity, etc. In your User Account
            information, you may designate or be required to designate affiliation with a such group; provided however, that use of your User Account is limited to you as an individual. The following shall apply to your User Account:
          </div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">a.</div>
          <div class="col px-0">
            We use reasonable precautions to protect the privacy of your username, password, and User Account information including your User Identity (defined in §3a below). You, however, are ultimately responsible for protecting your
            username, password, and User Account information from disclosure to third parties;
          </div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">b.</div>
          <div class="col px-0">You are not permitted to circumvent the use of required encryption technologies, if any;</div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">c.</div>
          <div class="col px-0">
            You agree to (i) immediately notify us of any unauthorized use of your username, password, or User Account, or any other breach of security, and (ii) ensure that you exit from your User Account at the end of each session;
          </div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">d.</div>
          <div class="col px-0">
            Usernames and passwords are non-transferable, and all users are obligated to take preventative measures to prohibit any other person or entity from accessing the Websites with his or her username, password, or other account
            information
          </div>
        </div>

        <div class="row mx-0 mt-3 ml-3 mr-3 text-justify">
          <div class="col-auto pl-0 pr-2"><b>3.</b></div>
          <div class="col px-0"><b> ACCURATE AND TRUTHFUL INFORMATION.</b> In creaKng and using your User Account for use on the Website, you agree to:</div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">a.</div>
          <div class="col px-0">Provide true, accurate, current, and complete informaKon about yourself (“User IdenKty”); and</div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">b.</div>
          <div class="col px-0">Maintain and promptly update your User IdenKty to keep it true, accurate, current, and complete.</div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">c.</div>
          <div class="col px-0">
            You agree that we may collect and use data and related informaKon, including but not limited to demographical informaKon, poliKcal informaKon, technical informaKon about your device, system and applicaKon solware, and
            peripherals, and that we may use this informaKon, as long as it is in a form that does not personally idenKfy you.
          </div>
        </div>

        <div class="row mx-0 mt-3 ml-3 mr-3 text-justify">
          <div class="col-auto pl-0 pr-2"><b>4.</b></div>
          <div class="col px-0">
            <b> ACCOUNT DEACTIVATION. </b>We reserve the right to, suspend, deacKvate, terminate or cancel your User Account, and/or remove some or all of any Content associated in any way with your User Account in our sole discreKon,
            including for the following reasons:
          </div>
        </div>
        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">a.</div>
          <div class="col px-0">You provide any informaKon that is untrue, inaccurate, not current, or incomplete, or if we have reasonable grounds to suspect that such informaKon is untrue, inaccurate, not current, or incomplete;</div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">b.</div>
          <div class="col px-0">You request such deacKvaKon;</div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">c.</div>
          <div class="col px-0">You are deceased;</div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">d.</div>
          <div class="col px-0">You do not respond to repeated communicaKon agempts;</div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">e.</div>
          <div class="col px-0">You reside in or relocate to a country where use of a User Account is prohibited under applicable law;</div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">f.</div>
          <div class="col px-0">You allow any other person or enKty to access the Websites using your username and password, and/or</div>
        </div>
        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">g.</div>
          <div class="col px-0">You act in a fraudulent or an inappropriate manner while using the User Account.</div>
        </div>

        <div class="row mx-0 mt-5 ml-3 mr-3 text-justify">
          <div class="col-auto pl-0 pr-2"><b>5.</b></div>
          <div class="col px-0">
            <b>OBJECTIONABLE MATERIAL.</b> You acknowledge that in using the Websites and accessing the Content, you may encounter material that you may deem to be inaccurate, harmful, biased, disturbing, offensive or objecKonable. You
            agree to use the Websites at your sole risk and that we shall have no liability to you for any such material.
          </div>
        </div>

        <div class="row mx-0 mt-3 ml-3 mr-3 text-justify">
          <div class="col-auto pl-0 pr-2"><b>6.</b></div>
          <div class="col px-0"><b> CONTENT</b></div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">a.</div>
          <div class="col px-0">
            TO PROTECT YOUR PRIVACY AND THE PRIVACY OF OTHERS, YOU WILL NOT PROVIDE ANY USER CONTENT THAT CONTAINS PERSONALLY IDENTIFIABLE INFORMATION (SUCH AS NAME, PHONE NUMBER, EMAIL OR MAILING ADDRESS, SOCIAL SECURITY NUMBER, ETC.)
            BELONGING TO ANYONE ELSE.
          </div>
        </div>
        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">b.</div>
          <div class="col px-0">UPLOADING IMAGES OR VIDEO OF OTHER PEOPLE WITHOUT THEIR EXPRESS WRITTEN PERMISSION IS STRICTLY PROHIBITED.</div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">c.</div>
          <div class="col px-0">You are solely responsible for your Content, and the consequences of posKng or publishing it</div>
        </div>
        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">d.</div>
          <div class="col px-0">
            You represent and warrant that: (i) you own or have the necessary licenses, rights, consents, and permissions to use, and to authorize us and other users of the Websites to publish your Content; and (ii) to the extent
            applicable, you have the wrigen consent, release, and/or permission of each idenKfiable individual person in your Content to use the name or likeness of each such person to enable inclusion and publicaKon of the Content.
          </div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">e.</div>
          <div class="col px-0">
            By providing Content to us, you hereby grant us a worldwide, non-exclusive, royalty-free, irrevocable, sublicenseable, and transferable license to use, reproduce, distribute, prepare derivaKve works of, display, publish,
            republish, and perform your Content, including, but not limited to, publicaKon of any porKon or all of your Content in an adverKsement, a book, a magazine, a spoken word work, any other literary work, and/or any other
            audio-visual work, in any medium or format, anywhere in the world.
          </div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">f.</div>
          <div class="col px-0">You acknowledge that we reserve the right to pre-screen your Content and that we can in our sole discreKon refuse, move, and/or remove any Content for any reason.</div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">g.</div>
          <div class="col px-0">
            You agree to not use the Website to: (i) upload, post, email, transmit or otherwise make available any Content that is unlawful, harmful, threatening, abusive, harassing, bullying, torKous, false, defamatory, vulgar, obscene,
            pornographic, sexually explicit, libelous, invasive of another’s privacy, hateful, or racially, ethnically or otherwise objecKonable; (ii) harm minors in any way; (iii) impersonate any person or enKty, including, but not limited
            to, an official or an employee of Jikia; (iv) falsely state or otherwise misrepresent your affiliaKon with any person or enKty; (v) forge headers or otherwise manipulate idenKfiers in order to disguise the origin of any Content;
            (vi) upload, post, email, transmit, or otherwise make available any unsolicited or unauthorized adverKsing, promoKonal materials, spam, phishing schemes, pyramid schemes, or any other form of solicitaKon; (vii) upload, post,
            email, transmit, or otherwise make available any material that contains solware that may harm the operaKon of the Websites and their delivery of the Content to users; and/or (viii) intenKonally or unintenKonally violate any
            applicable local, state, naKonal, or internaKonal law; (ix) stalk or otherwise harass another; or (x) collect or store personal data about other users.
          </div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">h.</div>
          <div class="col px-0">You acknowledge and agree that we may disclose your idenKty and/or User idenKty in connecKon with any claim of an intellectual property violaKon or any other courtordered purpose</div>
        </div>

        <div class="row mx-0 mt-3 ml-3 mr-3 text-justify">
          <div class="col-auto pl-0 pr-2"><b>7.</b></div>
          <div class="col px-0">
            <b>OUR PARTICIPATION. </b>We do not endorse any Content, or any opinion, recommendaKon, or advice expressed in the Content, and we expressly disclaim any and all liability in connecKon with Content. We do not knowingly permit
            copyright infringing acKviKes and infringement of intellectual property rights on the Websites. We reserve the right to remove Content without prior noKce. Without limiKng our right to cause the terminaKon of a User Account for
            any or no reason, we will also cause the terminaKon of a User Account if a user is determined to be a repeat offender, a repeat offender being a user who has been noKfied of inappropriate Content or behavior.
          </div>
        </div>

        <div class="row mx-0 mt-3 ml-3 mr-3 text-justify">
          <div class="col-auto pl-0 pr-2"><b>8.</b></div>
          <div class="col px-0"><b> NO WARRANTIES, LIMITATIONS OF LIABILITY, YOUR INDEMNIFICATION OF US.</b></div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">a.</div>
          <div class="col px-0">
            NO WARRANTIES FOR WEBSITES. We make no warranty concerning the delay, failure, interrupKon, or corrupKon of any data, the Content, or other informaKon transmiged in connecKon with the use of the Websites. YOU EXPRESSLY AGREE
            THAT YOUR USE OF THE WEBSITES IS AT YOUR SOLE RISK. THE WEBSITES, THE CONTENT IS PROVIDED “AS IS” AND “AS AVAILABLE” FOR YOUR USE, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, UNLESS SUCH WARRANTIES ARE LEGALLY
            INCAPABLE OF EXCLUSION. WE MAKE NO REPRESENTATIONS OR WARRANTIES THAT THE WEBSITES, THE CONTENT, OR ANY SERVICES OFFERED IN CONNECTION WITH THE WEBSITES, ARE OR WILL REMAIN UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE
            CORRECTED, OR THAT THE WEB PAGES ON OR THROUGH THE WEBSITES, OR THE SERVERS USED IN CONNECTION WITH THE WEBSITES, ARE OR WILL REMAIN FREE FROM ANY VIRUSES, WORMS, TIME BOMBS, DROP DEAD DEVICES, TROJAN HORSES, OR OTHER HARMFUL
            COMPONENTS. WE DO NOT GUARANTEE THAT YOU WILL BE ABLE TO ACCESS OR USE THE WEBSITES AT TIMES OR LOCATIONS OF YOUR CHOOSING, OR THAT WE WILL HAVE ADEQUATE CAPACITY FOR THE WEBSITES AS A WHOLE OR IN ANY SPECIFIC GEOGRAPHIC AREA.
            WE MAKE NO REPRESENTATION OR WARRANTY REGARDING GOVERNMENT COMPLIANCE OF ANY SOFTWARE USED IN RUNNING THE WEBSITES. OUR ENTIRE LIABILITY AND YOUR EXCLUSIVE REMEDY WITH RESPECT TO THE USE OF ANY SERVICE OR PRODUCT PROVIDED ON OR
            THROUGH THE WEBSITES WILL BE THE REFUND OF MONIES PAID BY YOU TO US, IF ANY.
          </div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">b.</div>
          <div class="col px-0">
            LIMITATION OF LIABILITY. TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT WILL WE BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES ARISING FROM YOUR USE OF OR INABILITY TO
            USE THE WEBSITES AND/OR ANY CONTENT PROVIDED IN CONNECTION WITH THE WEBSITES OR FOR ANY OTHER CLAIM RELATED IN ANY WAY TO YOUR USE OF THE WEBSITES AND/OR ANY CONTENT. ADDITIONALLY, WE SHALL NOT BE LIABLE FOR NEGATIVE
            REPERCUSSIONS TO ANY PARTY BASED ON THE USE OF OR INABILITY TO USE THE WEBSITES, INCLUDING BUT NOT LIMITED TO LOST GOODWILL OR LOST PROFITS. WE SHALL BE LIABLE ONLY TO THE EXTENT OF ACTUAL DAMAGES INCURRED BY YOU, NOT TO EXCEED
            THE AMOUNT YOU ACTUALLY PAID TO US FOR GOODS OR SERVICES IN THE PRIOR SIX (6) MONTHS, IF ANY. WE ARE NOT LIABLE FOR ANY PERSONAL INJURY, INCLUDING DEATH, OR PROPERTY DAMAGE CAUSED BY YOUR USE OR MISUSE OF THE WEBSITES, THE
            CONTENT AND/OR THE USER CONTENT. REMEDIES UNDER THESE EULA ARE EXCLUSIVE AND ARE LIMITED TO THOSE EXPRESSLY PROVIDED FOR IN THESE EULA. BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY
            FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES OR JURISDICTIONS OUR LIABILITY WILL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY APPLICABLE LAW
          </div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">c.</div>
          <div class="col px-0">
            INDEMNIFICATION. You agree to defend, indemnify, and hold Jikia harmless and our directors, officers, employees, and agents from and against any and all claims, demands, suits, proceedings, liabiliKes, judgments, losses,
            damages, expenses, and costs (including without limitaKon reasonable agorneys’ fees) assessed or incurred by us, directly or indirectly, with respect to or arising out of: (i) your failure to comply with these EULA; (ii) your
            breach of your obligaKons under these EULA; (iii) your use of Websites including your Content; and/or (iv) any claim that your Content caused damage to a third party.
          </div>
        </div>

        <div class="row mx-0 mt-3 ml-3 mr-3 text-justify">
          <div class="col-auto pl-0 pr-2"><b>9.</b></div>
          <div class="col px-0">
            <b>JURISDICTION AND VENUE. </b>You agree that the Websites shall be deemed to be solely based in California, and that the Websites shall be deemed a passive website that does not give rise to personal jurisdicKon over Jikia in
            jurisdicKons other than California. These EULA shall be governed by and construed according to the laws of the State of California, without reference to its choice of law principles. Any claim or cause of acKon arising out of or
            relaKng to the Websites shall be brought in the state or federal courts located in the Central District of California and you hereby agree to the jurisdicKon and venue of those courts for any such dispute. Any such claim or
            cause of acKon shall be barred permanently unless such claim or cause of acKon is brought within one year aler it arises. If (i) you are not a U.S. ciKzen; (ii) you do not reside in the U.S.; (iii) you are not accessing the
            Service from the U.S.; or (iv) you are a ciKzen of one of the countries idenKfied below, you hereby agree that any dispute or claim arising from this Agreement shall be governed by the applicable law set forth below, without
            regard to any conflict of law provisions, and you hereby irrevocably submit to the non-exclusive jurisdicKon of the courts located in the state, province or country idenKfied below whose law governs: If you are a ciKzen of any
            European Union country or Switzerland, Norway or Iceland, the governing law and forum shall be the laws and courts of your usual place of residence.
          </div>
        </div>
        <div class="row mx-0 mt-3 ml-3 mr-3 text-justify">
          <div class="col-auto pl-0 pr-2"><b>10.</b></div>
          <div class="col px-0"><b> MISCELLANEOUS </b></div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">a.</div>
          <div class="col px-0">
            InterpretaKon of EULA. Where possible, these EULA shall be interpreted in such manner as to be effecKve and valid under applicable law. Should any part of these EULA for any reason be declared invalid or void, such decision
            shall not affect the remaining porKon which will remain in full force and effect.
          </div>
        </div>

        <div class="row mx-0 ml-5 mr-5 mt-2 text-justify">
          <div class="col-auto pl-0 pr-2">b.</div>
          <div class="col px-0">
            Entrie Agreement. This is the enKre agreement between us relaKng to your use of the Website and supersedes any prior understandings or agreements, wrigen or oral. These EULA may not be modified by you, in wriKng or otherwise,
            unless agreed to in a wrigen document signed by Jikia.
          </div>
        </div> -->

<!-- <h6 class="bold">END USER LICENSE AGREEMENT</h6>
        <p class="text-justify">
          The following END USER LICENSE AGREEMENT (the “EULA”) governs the use of Jikia (“we,” “our,” or “us”)
          websites, located at www.keeku.com, or parKcipaKng in any online features, services and/or programs
          offered by us (collecKvely, the “Website”). This EULA is in effect for all of our Websites.
        </p>
        <br />
        <h6 class="bold">BY ACCESSING OR USING OUR WEBSITES, YOU ARE ENTERING INTO A LEGAL
          CONTRACT WITH JIKIA REGARDING YOUR USE OF THE WEBSITES AND YOU AGREE TO
          BE BOUND BY THIS EULA. IF YOU DO NOT AGREE TO ANY PORTION OF THIS EULA, YOU
          SHOULD NOT ACCESS OR OTHERWISE USE THE WEBSITE.
          YOU SHOULD CAREFULLY REVIEW THIS ENTIRE EULA. INCLUDED IN THIS LEGAL
          CONTRACT ARE (i) REQUIREMENTS THAT YOU PROVIDE TRUTHFUL, ACCURATE,
          CURRENT AND COMPLETE INFORMATION ABOUT YOUR IDENTITY; (ii) PROHIBITIONS
          ON ALLOWING ANYONE OR ANY ENTITY OTHER THAN YOURSELF USE OF YOUR USER
          ACCOUNT; AND (iii) TERMS CLEARLY INDICATING YOU ARE RESPONSIBLE FOR ANY
          LEGAL ISSUES CAUSED BY YOUR CONTENT OR YOUR USE OF THE WEBSITES.</h6>
        
        <h6 class="bold">TABLE OF CONTENTS</h6>

        <p class="text-justify">1. CONVENIENCE AND INFORMATION ONLY</p>
        <p class="text-justify">2. USER ACCOUNT</p>
        <p class="text-justify">3. ACCURATE AND TRUTHFUL INFORMATION</p>
        <p class="text-justify">4. ACCOUNT DEACTIVATION</p>
        <p class="text-justify">5. OBJECTIONABLE MATERIAL</p>
        <p class="text-justify">6. CONTENT</p>
        <p class="text-justify">7. OUR PARTICIPATION</p>
        <p class="text-justify">8. NO WARRANTIES, LIMITATIONS OF LIABILITY, YOUR INDEMNIFICATION OF US</p>
        <p class="text-justify">9. JURISDICTION AND VENUE</p>
        <p class="text-justify">10. MISCELLANEOUS</p>

        <br />
        <p class="text-justify">1.</p>
        <h6 class="bold">CONVENIENCE AND INFORMATION ONLY.</h6>
        <p class="text-justify">
          The Websites are provided to you as a convenience
          and for your informaKon only. By merely providing access to the Website, we do NOT guarantee,
          warrant or represent that:
        </p>
        <br />
        <p class="text-justify">a. Any materials, statements, reviews, raKngs, opinions, documents, images, graphics,
          logos, designs, audio, video, comments and any other informaKon provided from or on
          the Websites (collecKvely, the “Content”) is accurate or complete;</p>
          <p class="text-justify">b. The Content is up-to-date or current;</p>
          <p class="text-justify">c. We have any obligaKon to update any Content;</p>
          <p class="text-justify">d. We will keep your Content confidenKal or restrict third parKes from any use of your
            Content;</p>
          <p class="text-justify">e. Any use, copy, reproducKon, adaptaKon, publicaKon, performance, or audio recording of
            the Content is free from allegaKons, claims, or lawsuits, including those based on
            allegaKons of intellectual property infringement, libel or slanderous defamatory
            statements, and/or violaKons of the right of publicity, including personality rights, the
            use of one's idenKty, such as name, image, likeness, or other unequivocal idenKfiers;</p>
          <p class="text-justify">f. The Content is free from changes caused by a third party;</p>
          <p class="text-justify">g. Your access to the Website will be free from interrupKons, errors, viruses or other
            harmful components;</p>
          <p class="text-justify">h. No data breach will ever occur; and/or</p>
          <p class="text-justify">i. InformaKon transmiged through the Internet is secure, or that such transmissions are
            free from delay, interrupKon, intercepKon or error.</p>
          <p class="text-justify">2.</p>
          <h6 class="bold">USER ACCOUNT</h6>
          <p class="text-justify">To access the Website, we require you to select a username and password, and
            create a user account (“User Account”). User Accounts are unique to an individual real person.
            You are prohibited from creaKng or using a User Account on behalf of an enKty, organizaKon,
            group, non-profit, university or other educaKonal insKtuKon, poliKcal organizaKon, government
            or governmental agency or enKty, etc. In your User Account informaKon, you may designate or
            be required to designate affiliaKon with a such group; provided however, that use of your User
            Account is limited to you as an individual. The following shall apply to your User Account:</p>
            <p class="text-justify">a. We use reasonable precauKons to protect the privacy of your username, password, and
              User Account informaKon including your User IdenKty (defined in §3a below). You,
              however, are ulKmately responsible for protecKng your username, password, and User
              Account informaKon from disclosure to third parKes;</p>
            <p class="text-justify">b. You are not permiged to circumvent the use of required encrypKon technologies, if any;</p>  
            <p class="text-justify">c. You agree to (i) immediately noKfy us of any unauthorized use of your username,
              password, or User Account, or any other breach of security, and (ii) ensure that you exit
              from your User Account at the end of each session;</p>
            <p class="text-justify">d. Usernames and passwords are non-transferable, and all users are obligated to take
              preventaKve measures to prohibit any other person or enKty from accessing the
              Websites with his or her username, password, or other account informaKon</p>
            <p class="text-justify">3.</p>
            <h6 class="bold">ACCURATE AND TRUTHFUL INFORMATION</h6>
            <p class="text-justify">In creaKng and using your User Account for use on
              the Website, you agree to:</p>
            <p class="text-justify">a. Provide true, accurate, current, and complete informaKon about yourself (“User
              IdenKty”); and</p>
            <p class="text-justify">b. Maintain and promptly update your User IdenKty to keep it true, accurate, current, and
              complete.</p>
            <p class="text-justify">c. You agree that we may collect and use data and related informaKon, including but not
              limited to demographical informaKon, poliKcal informaKon, technical informaKon about
              your device, system and applicaKon solware, and peripherals, and that we may use this
              informaKon, as long as it is in a form that does not personally idenKfy you.</p>

              <p class="text-justify">4.</p>
              <h6 class="bold">ACCOUNT DEACTIVATION. </h6>
              <p class="text-justify">. We reserve the right to, suspend, deacKvate, terminate or cancel
                your User Account, and/or remove some or all of any Content associated in any way with your
                User Account in our sole discreKon, including for the following reasons: 
                </p>
 -->
<!-- </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-black mw-auto float-right mr-2" (click)="onAcceptTermAndCondition('false')">Deny</button>
        <button type="button" class="btn btn-black mw-auto float-right" (click)="onAcceptTermAndCondition('true')">Accept</button>
      </div>
    </div>
  </div> 
</div>-->

<div id="phoneNumberModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="phoneNumberModalTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-custom modal-theme" role="document">
    <div class="modal-content">
      <div class="modal-body p-5">
        <button aria-label="Close" class="close close-modal" data-dismiss="modal" type="button">
          <span class="fi flaticon-cancel"></span>
        </button>
        <h3 class="text-center">Text Verification</h3>
        <div *ngIf="otpSent == false">
          <form class="row m-0 px-2 px-sm-4 pt-2 pt-sm-4 square-form needs-validation" (ngSubmit)="(f.form.valid)"
            #f="ngForm" novalidate autocomplete="off">
            <div class="row m-0 mobile-input-with-flag">
              <label for="countryCode" class="col-12 m-0 pl-0 pb-2 control-label font-gray">
                Enter your mobile number</label>
              <div class="col-auto pl-0 form-group countryCode-flag mt-2">
                <img src="assets/images/flag_placeholder.png" class="flag flag-{{ countryCode }}" />
              </div>

              <div class="col pl-0 form-group mt-2">
                <select name="countryCode" class="py-0" id="countryCode" [(ngModel)]="countryCode" #code [ngClass]="{
                    'form-control': true,
                    'has-value': countryCode
                  }">
                  <option value="" selected>Select Country code</option>
                  <option value="us">+1</option>
                  <option value="in">+91</option>
                </select>
              </div>

              <div class="col-12 col-sm-8 col-md-7 pl-0 form-group mt-2">
                <input type="text" class="form-control min-width-20ch" id="phoneNumber" name="phoneNumber"
                  [(ngModel)]="phoneNumber" mask="(000) 000-0000" inputmode="tel" required minlength="10" />
                <div *ngIf="f.submitted" class="invalid-feedback">
                  <span *ngIf="f.submitted && (f.untouched || f.invalid)">
                    Please enter valid phone number
                  </span>
                  <span *ngIf="invalidPhone == true">
                    Invalid Phone Number.</span>
                  <span *ngIf="existPhoneNumber == true">This phone number is already verified. Please use different
                    number.</span>
                </div>
              </div>
              <div class="col-md-12 pt-md-5 pt-4 text-center">
                <input type="submit" class="btn btn-black" (click)="sendOTP(f.invalid)" value="Send Code" />
              </div>
            </div>
          </form>
        </div>
        <div *ngIf="otpSent">
          <form class="row m-0 px-2 px-sm-5 pt-2 pt-sm-5 square-form needs-validation" (ngSubmit)="onSubmit(f)"
            #f="ngForm" novalidate autocomplete="off">
            <div *ngIf="otpResent" class="col-md-12 my-2 alert alert-info">
              OTP sent again.
              <button (click)="otpResent = false" type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span>&times;</span>
              </button>
            </div>
            <div class="col-12">
              <label for="input" class="col-12 m-0 text-center control-label font-16 font-gray">Enter Code</label>

              <div class="col-10 activation-grid item-4 form-group mx-auto">
                <div [ngClass]="{
                    'is-invalid':
                      invalidOTP == true ||
                      (f.submitted && (f.untouched || f.invalid))
                  }" *ngFor="
                    let item of otpCodeArr;
                    let index = index;
                    trackBy: trackByIndex
                  ">
                  <div class="input-field my-0">
                    <input type="text" [attr.tabindex]="index + 1" [attr.id]="'otpCode' + index"
                      [attr.name]="'otpCode' + index" class="form-control text-center activation-field h-2"
                      maxlength="1" [(ngModel)]="otpCodeArr[index]" required inputmode="tel"
                      (paste)="onPestCode($event, 'otpCode', 4)" (input)="keytabOTP(index, $event, 'input')"
                      (keyup)="keytabOTP(index, $event, 'keyup')" />
                  </div>
                </div>
              </div>
              <div class="text-center mt-2 invalid-feedback d-block">
                <!-- invalid-feedback -->
                <span *ngIf="f.submitted && (f.untouched || f.invalid)">
                  Please enter verification code sent to your registered phone number.
                </span>
                <span *ngIf="invalidOTP"> Invalid OTP. </span>
              </div>
            </div>
            <!-- .col -->

            <div class="col-md-12 pt-md-5 pt-4 text-center">
              <button type="submit" class="btn btn-black mt-2" tabindex="5">
                Continue
              </button>
              <p class="font-14 font-gray mt-3">
                Didn't get verification code?
                <button type="button" (click)="resendOTP(f)" class="btn btn-link btn-link-theme font-14 p-0"
                  tabindex="6">
                  &nbsp;Resend now
                </button>
              </p>
              <p class="font-14 font-gray mt-3">
                Want to change number?
                <button type="button" (click)="changePhoneNumber()" class="btn btn-link btn-link-theme font-14 p-0"
                  tabindex="7">
                  &nbsp;Click here
                </button>
              </p>
            </div>
            <!-- .col -->
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="veriffModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="phoneNumberModalTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-custom modal-theme" role="document">
    <div class="modal-content">
      <div class="modal-body p-5">
        <button aria-label="Close" class="close close-modal" data-dismiss="modal" type="button">
          <span class="fi flaticon-cancel"></span>
        </button>
        <h3 class="text-center">Veriff Verification</h3>
        <div id="veriff-root"></div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="congratsModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-custom modal-dialog-centered modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title mt-2 mb-2">Congratulations!</h5>
        <button type="button" class="close mt-2 font-32" aria-label="Close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="text-justify theme-color mb-2">
          Congratulations! You have completed the first milestone towards your
          CitizeX profile creation.
        </p>
        <div class="col-12 text-center">
          <button class="btn btn-black mt-4 mb-2 mx-2 py-2 px-sm-2 px-3 font-14"
            (click)="redirectToSearchProfilePage()">
            Next
          </button>
        </div>
      </div>
      <!-- .modal-body -->
    </div>
    <!-- .modal-content -->
  </div>
  <!-- .modal-dialog -->
</div>
<!------------------Auto-Sign-in-Modal------------------>
<div class="modal fade" id="signupVerificationModal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <p class="fw-600">"CitizeX does not store any personal information you upload for identity verification.
          We use an industry leading third-party service called Veriff <a href="https://www.veriff.com/" class="fw-700"
            target="_blank">(www.veriff.com) </a>.
          You can read their personal data retention policy here: <a href="https://www.veriff.com/privacy-notice"
            class="fw-700" target="_blank">www.veriff.com/privacy-notice</a>."</p>
        <div class="col-12 text-center">
          <button type="button" class="btn btn-outline-black mt-4 mb-2 py-1 font-16 px-2 auto-signin-btn"
            data-dismiss="modal">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>